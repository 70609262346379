.container-mentions {
  display: flex;
  margin-top: 5%;
  width: 95%;
}
.sous-sontainer-mentions {
  display: flex;
  flex-flow: column wrap;
  width: 95%;
  align-content: center;
}
.card-présentation-site {
  margin: 0.5%;
  width: 70%;
  padding: 1%;
  font-family: "Varela Round", sans-serif;
  font-size: 0.8rem;
  border-color: #027aaa;
  border-style: solid;
  border-width: 2px;
}
.card-header-mentions {
  font-family: "Varela Round", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  padding: 0;
  height: 20px;
  color: white;
  background-color: #09adec;
  border-color: #027aaa;
}
.card-mentions-center {
  margin: 0.5%;
  margin-top: 3%;
  width: 70%;
  border-color: #027aaa;
  border-style: solid;
  border-width: 2px;
}
.card-mentions-side {
  margin: 0.5%;
  width: 95%;
  border-color: #027aaa;
  border-style: solid;
  border-width: 2px;
}
.card-mentions-text {
  padding: 1%;
  font-family: "Varela Round", sans-serif;
  font-size: 0.6rem;
}
.description-services {
  width: 70%;
  font-family: "Varela Round", sans-serif;
  font-size: 0.6rem;
  padding: 0.5%;
  border-radius: 5%;
  border-color: #027aaa;
  border-style: solid;
  border-width: 2px;
}
.propriété-site {
  width: 70%;
  padding: 0.5%;
  border-radius: 5%;
  border-color: #027aaa;
  border-style: solid;
  border-width: 2px;
}
.données-personelles-site {
  width: 70%;
  padding: 0.5%;
  border-radius: 5%;
  border-color: #027aaa;
  border-style: solid;
  border-width: 2px;
}
@media screen and (min-width: 1200px) {
  .card-présentation-site {
    font-size: 1.2rem;
  }
  .card-header-mentions {
    font-size: 1.4rem;
  }
  .card-mentions-text {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .card-présentation-site {
    font-size: 1.2rem;
  }
  .card-header-mentions {
    font-size: 1.4rem;
  }
  .card-mentions-text {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 991px) and (min-width: 769px) {
  .container-mentions {
    display: block;
  }
  .sous-sontainer-mentions {
    margin-bottom: 2%;
  }
  .card-mentions-side {
    margin-bottom: 2%;
  }
}

@media screen and (max-width: 768px) and (min-width: 577px) {
  .container-mentions {
    display: block;
  }
  .sous-sontainer-mentions {
    margin-bottom: 2%;
  }
  .card-présentation-site {
margin: auto;
width: 95%;
  }
  .card-mentions-side {
    margin-bottom: 2%;
  }
}

@media (max-width: 576px) {
  .container-mentions {
    display: block;
  }
  .sous-sontainer-mentions {
    margin-bottom: 2%;
  }
  .card-mentions-side {
    margin-bottom: 2%;
  }
}