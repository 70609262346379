.accroche-tarifs {
  font-family: "Varela Round", sans-serif;
  font-style: italic;
  text-align: center;
  font-size: 2.2rem;
  color: grey;
  margin-bottom: 1%;
}
.container-tarifs {
  display: flex;
  justify-content: space-around;
  max-width: 95%;
}
.colonne-tarifs {
  width: 45%;
}

.card-tarif {
  border-color: #027aaa;
  box-shadow: -10px 10px 7px -3px rgba(2, 122, 170, 0.79);
  -webkit-box-shadow: -10px 10px 7px -3px rgba(2, 122, 170, 0.79);
  -moz-box-shadow: -10px 10px 7px -3px rgba(2, 122, 170, 0.79);
}
.card-header-tarif {
  font-family: "Varela Round", sans-serif;
  font-size: 1.9rem;
  font-weight: bold;
  color: white;
  background-color: #027aaa;
  border-color: #027aaa;
}
.card-text-tarif {
  font-family: "Varela Round", sans-serif;
  font-size: 1.5rem;
  display: flex;
  text-align: left;
  justify-content: space-between;
  margin: 0;
}
.card-tarif-spe {
  border-color: #027aaa;
  box-shadow: -10px 10px 7px -3px rgba(2, 122, 170, 0.79);
  -webkit-box-shadow: -10px 10px 7px -3px rgba(2, 122, 170, 0.79);
  -moz-box-shadow: -10px 10px 7px -3px rgba(2, 122, 170, 0.79);
  margin-top: 25px;
}
.card-header-tarif-spe {
  font-family: "Varela Round", sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  color: white;
  padding: 1.2px;
  background-color: #027aaa;
  border-color: #027aaa;
}
.card-text-tarif-spe {
  font-family: "Varela Round", sans-serif;
  font-size: 1.5rem;
  display: flex;
  text-align: left;
  justify-content: space-between;
  margin: 0;
}
.card-footer-tarif-spe {
  font-family: "Varela Round", sans-serif;
  font-size: 1.8rem;
  color: #027aaa;
  padding: 1.1px;

  border-color: #027aaa;
}
.ul-tarif {
  margin: 4px;
  padding: 10px
}
.li-tarif {
  list-style: none;
  line-height: 1.9;
  text-align: center;
}
.card-footer-tarif {
  font-family: "Varela Round", sans-serif;
  font-size: 1.8rem;
  color: #027aaa;
  border-color: #027aaa;
}
.RDVTarif {
  margin-top: 2%;
  margin-bottom: 5%;
  height: 5%;
  max-width: 37%;
  font-family: "Varela Round", sans-serif;
  font-size: 1.5rem;
  background-color: #027aaa;
  border-color: #027aaa;
}
.RDVTarif:hover{
  background-color: #024a66;
}
.card-exemples {
  border-color: #027aaa;
  box-shadow: -10px 10px 7px -3px rgba(2, 122, 170, 0.79);
  -webkit-box-shadow: -10px 10px 7px -3px rgba(2, 122, 170, 0.79);
  -moz-box-shadow: -10px 10px 7px -3px rgba(2, 122, 170, 0.79);
  margin-top: 25px;
}
.card-header-exemples{
  font-family: "Varela Round", sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  color: white;
  padding: 1.2px;
  background-color: #027aaa;
  border-color: #027aaa; 
}
.card-text-exemples {
  font-family: "Varela Round", sans-serif;
  font-size: 1.5rem;
  text-align: left;
  justify-content: space-between;
  margin: 0;
}
.lien-exemples{
  color: #1f65af;
}
.card-footer-exemples {
  font-family: "Varela Round", sans-serif;
  font-size: 1.8rem;
  color: #027aaa;
  border-color: #027aaa;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .titre-page {
    margin-top: 1%;
  }
  .card-header-tarif {
    font-size: 1.7rem;
  }

  .card-text-tarif {
    font-size: 1.4rem;
  }

  .card-header-tarif-spe {
    font-size: 1.7rem;
  }

  .card-footer-tarif-spe {
    font-size: 1.7rem;
  }

  .li-tarif {
    list-style: inside;
  }
  .RDVTarif {
    height: fit-content;
  }

  .card-footer-tarif {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 991px) and (min-width: 769px) {
  .titre-page {
    margin-top: 0.8%;
  }
  .card-header-tarif {
    font-size: 1.5rem;
  }

  .card-text-tarif {
    font-size: 1.2rem;
  }

  .card-header-tarif-spe {
    font-size: 1.5rem;
  }

  .card-footer-tarif-spe {
    font-size: 1.5rem;
  }

  .li-tarif {
    list-style: inside;
  }
  .RDVTarif {
    height: fit-content;
  }

  .card-footer-tarif {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) and (min-width: 577px) {
  .titre-page {
    margin-top: 1.5%;
  }
  .accroche-tarifs {
    font-size: 2rem;
    margin-bottom: 4%;
  }

  .card-header-tarif {
    font-size: 1.2rem;
  }

  .card-text-tarif {
    font-size: 1rem;
  }

  .card-header-tarif-spe {
    font-size: 1.2rem;
  }
  .card-header-exemples{
    
  }

  .card-footer-tarif-spe {
    font-size: 1.2rem;
  }

  .li-tarif {
    list-style: inside;
  }
  .RDVTarif {
    height: fit-content;
  }
  .card-footer-tarif {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .titre-page {
    margin-top: 1.5%;
  }
  .accroche-tarifs {
    font-size: 1.8rem;
    margin-bottom: 4%;
  }
  .container-tarifs {
    display: block;
  }
  .colonne-tarifs {
    width: 95%;
    margin-bottom: 5%;
  }

  .card-header-tarif {
    font-size: 1.2rem;
  }

  .card-text-tarif {
    font-size: 1rem;
  }

  .card-header-tarif-spe {
    font-size: 1.2rem;
  }

  .card-footer-tarif-spe {
    font-size: 1.2rem;
  }

  .li-tarif {
    list-style: inside;
  }
  .RDVTarif {
    height: fit-content;
  }
  .card-footer-tarif {
    font-size: 1.2rem;
  }
}
