@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&family=Varela+Round&display=swap");
.App {
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  overflow-y: scroll;
  height: 80.3vh;
}
.titre-page {
  margin-top: 1%;
  font-family: "Varela Round", sans-serif;
  text-align: center;
  font-size: 3.5rem;
  color: #027aaa;
  //font-weight: bold;
  text-decoration: #027aaa underline;
}
/* .accroche-page {
  font-family: "Roboto", sans-serif;
  font-style: italic;
  text-align: center;
  font-size: 1.4rem;
  color: black;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
@media screen and (min-width: 1200px) {
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .titre-page {
    font-size: 3.3rem;
    margin-top: 3%;
  }
}
@media screen and (max-width: 991px) and (min-width: 769px) {
  .titre-page {
    font-size: 3.3rem;
    margin-top: 3%;
  }
}
@media screen and (max-width: 768px) and (min-width: 577px) {
  .titre-page {
    font-size: 2.5rem;
    margin-top: 3%;
  }
}
@media (max-width: 576px) {
  .titre-page {
    font-size: 2.5rem;
    margin-top: 3%;
  }
}
