.carousel {
  height: 78vh;
}
.slide {
  color: #027aaa;
}
.carousel-inner {
  height: 90%;
  margin-top: 1%;
  margin-bottom: 1%;
}
.base-slide1 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  //background-image: url("./../Images/fondslide1.jpg");
  background-position: 100% 50%;

  opacity: 1;
  visibility: inherit;
  background-size: 99.963% 159.973%;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.titre-slide1 {
  margin-top: 7%;
  font-size: 4.5rem;
  font-family: "Varela Round", sans-serif;
  font-weight: bold;
  position: relative;
  width: 35%;
  margin-left: 31%;
  margin-right: auto;
}
.resume-slide1 {
  font-size: 2.5rem;
  font-family: "Varela Round", sans-serif;
  font-style: italic;
  position: relative;
  width: 35%;
  margin-left: 31%;
  margin-right: auto;
}
.base-slide2 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-image: url("./../Images/fondslide2.png");
  background-position: 100% 50%;

  opacity: 1;
  visibility: inherit;
  background-size: 99.963% 116.973%;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.titre-slide2 {
  margin-top: 9%;
  font-size: 4rem;
  font-family: "Varela Round", sans-serif;
  font-weight: bold;
  border-radius: 35% 0 35% 0;
  position: relative;
  width: 35%;
  margin-left: 28%;
  margin-right: auto;
}
.resume-slide2 {
  font-size: 2.5rem;
  font-family: "Varela Round", sans-serif;
  font-style: italic;
  border-radius: 35% 0 35% 0;
  position: relative;
  width: 35%;
  margin-left: 28%;
  margin-right: auto;
}

.base-slide3 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-image: url("./../Images/fondslide3.jpg");
  background-position: 100% 50%;

  opacity: 1;
  visibility: inherit;
  background-size: 99.963% 116.973%;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.titre-slide3 {
  margin-top: 8%;
  margin-bottom: 30%;
  font-size: 4rem;
  font-family: "Varela Round", sans-serif;
  font-weight: bold;
  position: relative;
  width: 35%;
  margin-left: 33%;
  margin-right: auto;
}

.resume-slide3 {
  font-size: 2.5rem;
  font-family: "Varela Round", sans-serif;
  font-style: italic;
  position: relative;
  width: 45%;
  margin-top: 23%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1200px) {
  .base-slide1 {
    background-image: url("./../Images/fondslide1.jpg");
  }

  .titre-slide2 {
    background-color: white;
  }
  .resume-slide2 {
    background-color: white;
  }
  .titre-slide3 {
    margin-top: 8%;
    margin-bottom: 20%;
  }
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .carousel-inner {
    height: 75%;
    margin-top: 10%;
    margin-bottom: 1%;
  }
  .base-slide1 {
    background-image: url("./../Images/fondslide1.jpg");
  }
  .titre-slide1 {
    font-size: 4rem;
  }
  .resume-slide1 {
    font-size: 2rem;
  }
  .titre-slide2 {
    margin-top: 5%;
    background-color: white;
  }
  .resume-slide2 {
    background-color: white;
  }
  .titre-slide3 {
    margin-top: 8%;
    margin-bottom: 20%;
  }
}
@media screen and (max-width: 991px) and (min-width: 769px) {
  .carousel-inner {
    height: 65%;
    margin-top: 13%;
    margin-bottom: 1%;
  }
  .base-slide1 {
    background-image: url("./../Images/fondslide1.jpg");
  }
  .titre-slide1 {
    font-size: 3.2rem;
  }
  .resume-slide1 {
    font-size: 1.9rem;
  }
  .titre-slide2 {
    margin-top: 7%;
    background-color: white;
    font-size: 3.2rem;
    border-radius: 0 0 35% 35%;
  }
  .resume-slide2 {
    font-size: 2.2rem;
    background-color: white;
    border-radius: 0 0 35% 35%;
  }
  .titre-slide3 {
    font-size: 3.1rem;
  }

  .resume-slide3 {
    margin-top: 18%;
    font-size: 2rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 577px) {
  .carousel {
    height: 87vh;
  }
  .carousel-inner {
    height: 99%;
    margin-top: 2%;
    margin-bottom: 1%;
  }
  .base-slide1 {
    background-image: url("./../Images/fondslide1petitbis.jpg");
    background-position: 100% 36%;
  }
  .titre-slide1 {
    margin-top: 28%;
    width: 48%;
    margin-left: auto;
    margin-right: auto;
    font-size: 3.5rem;
  }
  .resume-slide1 {
    margin-top: 8%;
    width: 48%;
    margin-left: auto;
    margin-right: auto;
    font-size: 2.8rem;
  }
  .base-slide2 {
    background-image: url("./../Images/ipad.jpg");
    background-position: 100% 36%;
  }
  .titre-slide2 {
    margin-top: 35%;
    margin-left: 33%;
    background-color: 0;
    font-size: 3.2rem;
    border-radius: 0 0 35% 35%;
  }
  .resume-slide2 {
    margin-left: 33%;
    margin-top: 5%;
    font-size: 2.2rem;
    border-radius: 0 0 35% 35%;
  }
  .base-slide3 {
    background-image: url("./../Images/fondslide3petit.jpg");
    background-position: 100% 36%;
  }
  .titre-slide3 {
    margin-top: 40%;
    margin-left: 5%;
    font-size: 3.2rem;
    margin-bottom: 0.5%;
  }

  .resume-slide3 {
    margin-top: 2%;
    margin-left: 60%;
    font-size: 2.2rem;
  }
}
@media (max-width: 576px) {
  .carousel {
    height: 87vh;
  }
  .carousel-inner {
    height: 99%;
    margin-top: 2%;
    margin-bottom: 1%;
  }
  .base-slide1 {
    background-image: url("./../Images/fondslide1petitbis.jpg");
    background-position: 100% 36%;
  }
  .titre-slide1 {
    margin-top: 22%;
    width: 48%;
    margin-left: auto;
    margin-right: auto;
    font-size: 3rem;
  }
  .resume-slide1 {
    margin-top: 8%;
    width: 48%;
    margin-left: auto;
    margin-right: auto;
    font-size: 2.2rem;
  }
  .base-slide2 {
    background-image: url("./../Images/ipad.jpg");
    background-position: 100% 36%;
  }
  .titre-slide2 {
    margin-top: 25%;
    margin-left: auto;
    background-color: 0;
    font-size: 3rem;
    border-radius: 0 0 35% 35%;
  }
  .resume-slide2 {
    margin-left: auto;
    margin-top: 5%;
    font-size: 2.2rem;
    border-radius: 0 0 35% 35%;
  }
  .base-slide3 {
    background-image: url("./../Images/fondslide3micro.jpg");
    background-position: 100% 36%;
  }
  .titre-slide3 {
    margin-top: 20%;
    margin-left: 4%;
    font-size: 3rem;
    margin-bottom: 0;
  }

  .resume-slide3 {
    width: 43%;
    margin-top: 0;
    margin-left: 53%;
    font-size: 2.2rem;
  }
}
