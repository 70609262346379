.footer {
  position: absolute;
  display: flex;
  background-color: white;
  box-shadow: 0px -10px 7px -3px rgba(2, 122, 170, 0.79);
  -webkit-box-shadow: 0px -10px 7px -3px rgba(2, 122, 170, 0.79);
  -moz-box-shadow: 0px -10px 7px -3px rgba(2, 122, 170, 0.79);
  justify-content: center;
}
.flex-footer {
  display: flex;
  align-items: center;
}

.gitlab {
  color: #fc6d27;
}
.linkedin {
  color: #0e76a8;
}
.div-copyright {
  display: flex;
  align-items: center;
}
.copyright {
  height: 15px;
}
.copyright-text {
  font-family: "Varela Round", sans-serif;
  font-size: 0.5rem;
  vertical-align: bottom;
  margin-top: unset;
  margin-bottom: unset;
  margin-right: 10px;
}
@media screen and (min-width: 1200px) {
  .footer {
    min-height: 50px;
    margin-top: 2%;
    width: 100%;
    justify-content: center;
  }
  .app-link {
    margin-right: 30px;
  }
  .image {
    width: 5%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .footer {
    min-height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .app-link {
    margin-right: 30px;
  }
  .image {
    width: 5%;
  }
}
@media screen and (max-width: 991px) and (min-width: 769px) {
  .footer {
    min-height: 50px;
    bottom: 0;
    width: 100%;
    justify-content: space-evenly;
  }
  .app-link {
    margin-right: 30px;
  }
  .image {
    width: 5%;
  }
}
@media screen and (max-width: 768px) and (min-width: 577px) {
  .footer {
    min-height: 50px;
    bottom: 0;
    width: 100%;
    justify-content: space-evenly;
  }
  .app-link {
    margin-right: 30px;
  }
  .image {
    width: 5%;
  }
}
@media (max-width: 576px) {
  .footer {
    min-height: 50px;
    bottom: auto;
    width: 100%;
    justify-content: space-evenly;
  }
  .app-link {
    margin-right: 30px;
  }
  .image {
    width: 5%;
  }
}
