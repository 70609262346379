.accroche-apropos {
  font-family: "Varela Round", sans-serif;
  font-style: italic;
  text-align: center;
  font-size: 2.2rem;
  color: grey;
  margin-bottom: 2%;
}
.container-apropos {
  display: flex;
  max-width: 95%;
}
.map {
  align-items: center;
  margin: 1%;
  height: 340px;
  width: 100%;
}
.localisation {
  width: 33%;
  font-family: "Varela Round", sans-serif;
  font-size: 1.5rem;
  padding: 0.5%;
  border-radius: 5%;
  border-color: #027aaa;
  border-style: solid;
  border-width: 2px;
}
.texte-localisation {
  font-family: "Varela Round", sans-serif;
  font-size: 1.5rem;
}
.definition {
  font-family: "Varela Round", sans-serif;
  font-style: italic;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 2%;
  margin-bottom: 0;
}
.biographie {
  font-family: "Varela Round", sans-serif;
  font-family: "Varela Round", sans-serif;
  font-size: 1.5rem;
  margin-left: 5%;
  margin-right: 5%;
  width: 33%;
  padding: 0.5%;
}
.texte-bio {
  margin-bottom: 0;
}
.enseeiht {
  margin: 3%;
  width: 30%;
}
.safti {
  margin: 3%;
  width: 30%;
}
.compétences {
  font-family: "Varela Round", sans-serif;
  font-size: 1.5rem;
  width: 33%;
  padding: 0.5%;
  border-radius: 5%;
  border-color: #027aaa;
  border-style: solid;
  border-width: 2px;
}
.techno {
  display: flex;
  justify-content: space-between;
}
.autres-technos {
  margin-top: 10%;
}
.level {
  width: 72%;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
}

@media screen and (max-width: 991px) and (min-width: 769px) {
  .container-apropos {
    display: block;
  }
  .localisation {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .map {
    width: 90%;
    height: 250px;
    z-index: 20;
  }
  .texte-localisation {
    font-size: 1.4rem;
  }
  .definition {
    font-size: 1.2rem;
    margin-top: 2%;
  }
  .biographie {
    margin-top: 2%;
    width: 95%;
    font-size: 1.4rem;
  }
  .enseeiht {
    margin: 1%;
    width: 20%;
  }
  .safti {
    margin: 1%;
    width: 20%;
  }

  .compétences {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
    margin-bottom: 3%;
  }
  .techno {
    justify-content: right;
  }
  .level {
    margin-left: 3%;
    margin-right: 5%;
    width: 65%;
  }
  .autres-technos {
    margin-top: 3%;
  }
}

@media screen and (max-width: 768px) and (min-width: 577px) {
  .accroche-apropos {
    font-size: 2rem;
  }
  .container-apropos {
    display: block;
  }
  .localisation {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .map {
    width: 90%;
    height: 250px;
    z-index: 20;
  }
  .texte-localisation {
    font-size: 1.4rem;
  }
  .definition {
    display: none;
  }
  .biographie {
    margin-top: 2%;
    width: 95%;
    font-size: 1.4rem;
  }
  .enseeiht {
    margin: 1%;
    width: 20%;
  }
  .safti {
    margin: 1%;
    width: 20%;
  }

  .compétences {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
    margin-bottom: 3%;
  }
  .techno {
    justify-content: right;
  }
  .level {
    margin-left: 3%;
    margin-right: 5%;
    width: 65%;
  }
  .autres-technos {
    margin-top: 3%;
  }
}

@media (max-width: 576px) {
  .accroche-apropos {
    font-size: 1.8rem;
  }
  .container-apropos {
    display: block;
  }
  .localisation {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .map {
    width: 90%;
    height: 250px;
    z-index: 20;
  }
  .texte-localisation {
    font-size: 1.4rem;
  }
  .definition {
    display: none;
  }
  .biographie {
    margin-top: 2%;
    width: 95%;
    font-size: 1.4rem;
  }
  .enseeiht {
    margin: 1%;
    width: 20%;
  }
  .safti {
    margin: 1%;
    width: 20%;
  }

  .compétences {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
    margin-bottom: 3%;
  }
  .techno {
    justify-content: right;
  }
  .level {
    margin-left: 3%;
    margin-right: 5%;
    width: 65%;
  }
  .autres-technos {
    margin-top: 3%;
  }
}
