.accroche-services {
  font-family: "Varela Round", sans-serif;
  font-style: italic;
  text-align: center;
  border-radius: 10px;
  font-size: 2.2rem;
  color: grey;
  margin-bottom: 3%;
}
.container-services {
  display: flex;
  justify-content: space-around;
}
.service {
  border-color: #027aaa;
  border-width: 2px;
  border-style: solid;
  border-radius: 10% 0 10% 0;
  box-shadow: -10px 10px 7px -3px rgba(2, 122, 170, 0.79);
  -webkit-box-shadow: -10px 10px 7px -3px rgba(2, 122, 170, 0.79);
  -moz-box-shadow: -10px 10px 7px -3px rgba(2, 122, 170, 0.79);
  font-family: "Varela Round", sans-serif;
  margin: 9%;
}
.titre-service {
  font-size: 2rem;
  color: #027aaa;
}
.texte-service {
  font-size: 1.8rem;
  color: grey;
}
.service:hover {
  /*   .titre-service {
    color: black;
    transition: 0.5s;
  }
  .texte-service {
    color: #027aaa;
    transition: 0.5s;
  } */
}
.icon-service {
  font-size: 350%;
  margin-bottom: 2%;
}
@media screen and (min-width: 1200px) {
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .accroche-services {
    font-size: 2.2rem;
    margin-bottom: 3%;
  }
  .titre-service {
    font-size: 2rem;
  }
  .texte-service {
    font-size: 1.8rem;
  }
  .icon-service {
    font-size: 350%;
  }
}

@media screen and (max-width: 991px) and (min-width: 769px) {
  .accroche-services {
    font-size: 2.2rem;
    margin-bottom: 5%;
  }
  .titre-service {
    font-size: 2rem;
  }
  .texte-service {
    font-size: 1.8rem;
  }
  .icon-service {
    font-size: 350%;
  }
}

@media screen and (max-width: 768px) and (min-width: 577px) {
  .accroche-services {
    font-size: 2rem;
    margin-bottom: 5%;
  }
  .service {
    margin: 20% 15% 20% 15%;
  }

  .titre-service {
    font-size: 1.8rem;
  }
  .texte-service {
    font-size: 1.5rem;
  }
  .icon-service {
    font-size: 300%;
  }
}

@media (max-width: 576px) {
  .accroche-services {
    font-size: 1.8rem;
    margin-bottom: 10%;
  }
  .container-services {
    display: block;
  }

  .service {
    margin: 10%;
  }

  .titre-service {
    font-size: 1.5rem;
  }
  .texte-service {
    font-size: 1.2rem;
  }
  .icon-service {
    font-size: 250%;
  }
}
