.accroche-contact {
  font-family: "Varela Round", sans-serif;
  font-style: italic;
  color: grey;
  font-size: 2.2rem;
  max-width: 92%;
  padding: 5px;
  margin: auto;
  text-align: center;
}
.container-contact {
  width: 95%;
  display: flex;
}
.capsule-contact {
  width: 95%;
  border-left-color: #027aaa;
  border-left-style: solid;
  align-items: center;
}
.texte-contact {
  font-family: "Varela Round", sans-serif;
  font-size: 1.6rem;
  text-decoration: underline;
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 10%;
  text-align: center;
}
.texte-autre-contact {
  font-family: "Varela Round", sans-serif;
  font-size: 1.6rem;
  text-decoration: underline;
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 5%;
  text-align: center;
}
.span-contact {
  font-family: "Varela Round", sans-serif;
  font-size: 1.5rem;
}
.moyens-contact {
  font-family: "Varela Round", sans-serif;
  font-size: 1.6rem;
  text-align: center;
}
.formulaire {
  width: 105%;
  margin: auto;
  display: block;
  position: relative;
}
.RDV {
  margin-bottom: 5%;
  max-width: 37%;
  font-family: "Varela Round", sans-serif;
  font-size: 1.5rem;
  background-color: #027aaa;
  border-color: #027aaa;
}
.RDV:hover {
  background-color: #024a66;
}
.picto {
  margin-right: 5px;
  vertical-align: middle;
}
.zoom {
  height: 28px;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .container-contact {
    margin-top: 5%;
  }
}

@media screen and (max-width: 991px) and (min-width: 769px) {
  .container-contact {
    margin-top: 1%;
    margin-bottom: 2%;
    height: 100%;
  }
  .capsule-contact {
    margin-top: 10%;
  }

  .formulaire {
    margin-top: 10%;
  }
}

@media screen and (max-width: 768px) and (min-width: 577px) {
  .accroche-contact {
    font-size: 2rem;
  }

  .container-contact {
    margin-top: 1%;
    display: block;
    margin-bottom: 4%;
  }
  .capsule-contact {
    width: 95%;
    border-left-style: none;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 576px) {
  .accroche-contact {
    font-size: 1.8rem;
  }

  .container-contact {
    margin-top: 1%;
    display: block;
    margin-bottom: 4%;
  }
  .capsule-contact {
    width: 95%;
    border-left-style: none;
    margin-left: auto;
    margin-right: auto;
  }
}
