.basic {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.icon {
  margin-right: 2%;
  vertical-align: middle;
}
.input {
  font-size: 1.5rem;
  margin-top: 1%;
  padding:5px;    
  border-radius: 5px;
  width: 40%;
}
.input-message {
  font-size: 1.5rem;
  vertical-align: middle;
  margin-top: 1%;
  border-radius: 5px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  text-align: start;
  width: 60%;
  height: 300px;
}
.error-message {
  font-size: 1.5rem;
  color: #027aaa;
}
.bouton {
  font-family: "Varela Round", sans-serif;
  font-size: 15px;
  background-color: #027aaa;
  border-color: #027aaa;
  border-radius: 5px;
}
.bouton:hover {
  background-color: #024a66;
}
.recaptcha {
  position: relative;
  margin: auto;
}
@media (min-width: 1200px) {
  .input {
    font-size: 1.5rem;
  }
  .input-message {
    font-size: 1.5rem;
  }
  .error-message {
    font-size: 1.3rem;
  }
}
@media (min-width: 992px) {
  .input {
    font-size: 1.3rem;
  }
  .input-message {
    font-size: 1.3rem;
  }
  .error-message {
    font-size: 1.1rem;
  }
}
@media (min-width: 768px) {
}
@media (min-width: 576px) {
}
@media (max-width: 576px) {
}
